import { formatUnits } from "viem";
import { GRAMATIK_PROXY } from "../constants";
import { useWeb3Store } from "../stores/web3store";
import { ConnectWalletButton } from "./ConnectWalletButton";

export function Footer() {
  const state = useWeb3Store();
  return (
    <div className="flex text-dark-5 gap-2 text-xs items-center justify-center flex-col mt-6">
      <ConnectWalletButton checkOnly>
        <p>
          Total GRMTK staked:{" "}
          {formatUnits(BigInt(state.totalStakedGlobal ?? 0), 18)}
        </p>
        <p>
          Total cumulative rewards:{" "}
          {formatUnits(BigInt(state.totalCumulativeRewards ?? 0), 18)} ETH
        </p>
      </ConnectWalletButton>
      <p>{GRAMATIK_PROXY}</p>
    </div>
  );
}
