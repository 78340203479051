type InputProps = {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
};
export function Input({ value, onChange, placeholder }: InputProps) {
  return (
    <input
      className="bg-[#141414] text-sm font-mono px-[16px] py-[21px] w-full max-w-[500px]"
      type="text"
      value={value}
      placeholder={placeholder}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
}
