import React, { useEffect, useState } from "react";
import { Wrap } from "./Wrap";
import { Button } from "./Button";
import { ConnectWalletButton } from "./ConnectWalletButton";
import { useWeb3Store } from "../stores/web3store";
import { formatUnits } from "viem";
import { useMediaQuery } from "usehooks-ts";
import { NewTab } from "./Svg/NewTab";

const Content = ({ balance, address }: any) => (
  <div className="flex gap-6 flex-col sm:flex-row">
    <a
      target="_blank"
      className="inline-flex gap-2 items-center text-highlight"
      href="https://www.gramatik.net/"
    >
      Gramatik.net{" "}
      <span className="w-[14px] inline-block">
        <NewTab />
      </span>
    </a>
    <ConnectWalletButton checkOnly>
      <>
        <div className="flex gap-4 flex-col sm:flex-row">
          <div>{address}</div>
          <span className="hidden sm:inline-block">|</span>
          <div>
            Balance: {balance ? formatUnits(BigInt(balance), 18) : "0"} GRMTK
          </div>
        </div>
      </>
    </ConnectWalletButton>
  </div>
);

export function Navigation() {
  const { address: _address = "", balance } = useWeb3Store((state) => ({
    address: state.address,
    balance: state.balance,
  }));
  const isSmallScreen = useMediaQuery("(max-width: 640px)");
  const [open, updateOpen] = useState(false);
  useEffect(() => {
    if (open) {
      updateOpen(false);
    }
  }, [isSmallScreen]);

  const address = `${_address.substring(0, 5)}...${_address.substring(
    _address.length - 5
  )}`;
  return (
    <div className="h-24 border-b border-white">
      <Wrap>
        <div className="w-full h-full flex justify-between items-center">
          <img className="h-full w-auto" src="/gramatik-logo.png"></img>
          {!isSmallScreen && <Content balance={balance} address={address} />}
          {isSmallScreen && (
            <Button className="border-0" onClick={() => updateOpen(!open)}>
              Menu
            </Button>
          )}
          {isSmallScreen && open && (
            <div className="p-5 fixed w-full h-full gap-5 top-0 left-0 flex items-center flex-col justify-center z-50 bg-dark-1 animate-fade-up">
              <Content balance={balance} address={address} />
              <Button
                className="w-full border-0"
                onClick={() => updateOpen(false)}
              >
                Close
              </Button>
            </div>
          )}
        </div>
      </Wrap>
    </div>
  );
}
