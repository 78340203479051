import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { useWeb3Store } from "./web3store";
import { PROOF_PATH, SNAP_PATH } from "../constants";

interface AppState {
  proofs?: any;
  snapshot?: any;
  proof?: any;
  getAndSetProof: () => Promise<void>;
}

export const useAppStore = create<AppState>()(
  devtools(
    persist(
      (set) => ({
        getAndSetProof: async () => {
          const address = useWeb3Store.getState().address;
          if (!address) return;
          const snapshot = (
            (await fetch(SNAP_PATH).then((res) => res.json())) as any
          ).find((item: any) => item.wallet.toLowerCase() === address);
          const data = await fetch(PROOF_PATH).then((res) => res.json());
          set({
            proofs: data,
            proof: data[address],
            snapshot,
          });
        },
      }),
      {
        name: "AppStore",
        partialize: (state) => ({}),
      }
    ),
    { name: "AppStore" }
  )
);
