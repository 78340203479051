import React from "react";
import { useWeb3Store } from "../stores/web3store";
import { Button } from "./Button";
import { shallow } from "zustand/shallow";

export function ConnectWalletButton({
  children,
  checkOnly,
}: {
  children: React.ReactNode;
  checkOnly?: boolean;
}) {
  const { connect, connected, connecting } = useWeb3Store(
    (state) => ({
      connect: state.connect,
      connecting: state.isConnecting,
      connected: state.isConnected,
    }),
    shallow
  );
  return (
    <>
      {!connected && !checkOnly && (
        <Button disabled={connecting} onClick={connect}>
          Connect Wallet
        </Button>
      )}
      {connected && children}
    </>
  );
}
