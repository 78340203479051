import React, { useEffect, useMemo, useState } from "react";
import { useWeb3Store } from "../../stores/web3store";
import { ConnectWalletButton } from "../ConnectWalletButton";
import { Address, formatUnits, parseUnits } from "viem";
import { Button } from "../Button";
import { GRAMATIK_PROXY } from "../../constants";
import gramatik from "../../abi/gramatik";
import { useTxToast } from "../../hooks/useTxToast";
import { Input } from "../Input";

function Row({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex justify-between items-center gap-4 flex-col sm:flex-row">
      {children}
    </div>
  );
}

export function Stake() {
  const {
    connected,
    address,
    totalStakedPersonal,
    pendingRewards,
    balance,
    walletClient,
    onClaimed,
    publicClient,
  } = useWeb3Store((state) => ({
    connected: state.isConnected,
    address: state.address,
    totalStakedPersonal: state.totalStakedPersonal,
    pendingRewards: state.pendingRewards,
    balance: state.balance,
    publicClient: state.publicClient,
    walletClient: state.walletClient,
    onClaimed: state.onClaimed,
  }));
  const { transactionSubmitted, transactionComplete } = useTxToast();
  const [amountToStake, updateAmountToStake] = useState("");
  const { unstake, claim, stake } = useMemo(() => {
    const claim = async () => {
      try {
        const hash = await walletClient?.writeContract({
          address: GRAMATIK_PROXY as Address,
          abi: gramatik,
          functionName: "withdrawRewards",
          account: address as Address,
          chain: null,
        });
        transactionSubmitted(hash);
        await publicClient?.waitForTransactionReceipt({
          hash: hash!,
        });
        transactionComplete(hash);
        onClaimed();
        // updateDone(true);
      } catch (e) {
      } finally {
      }
    };
    const stake = async () => {
      try {
        const hash = await walletClient?.writeContract({
          address: GRAMATIK_PROXY as Address,
          abi: gramatik,
          functionName: "stake",
          account: address as Address,
          args: [parseUnits(amountToStake, 18)],
          chain: null,
        });
        transactionSubmitted(hash);
        await publicClient?.waitForTransactionReceipt({
          hash: hash!,
        });
        transactionComplete(hash);
        updateAmountToStake("");
        onClaimed();
        // updateDone(true);
      } catch (e) {
      } finally {
      }
    };
    const unstake = async () => {
      try {
        const hash = await walletClient?.writeContract({
          address: GRAMATIK_PROXY as Address,
          abi: gramatik,
          functionName: "unstake",
          account: address as Address,
          chain: null,
        });
        transactionSubmitted(hash);
        await publicClient?.waitForTransactionReceipt({
          hash: hash!,
        });
        transactionComplete(hash);
        onClaimed();
        // updateDone(true);
      } catch (e) {
      } finally {
      }
    };

    return { claim, unstake, stake };
  }, [address, onClaimed, publicClient, amountToStake, walletClient]);
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        {!connected && (
          <p className="mb-4">
            Connect your wallet to stake GRMTK token and earn.
          </p>
        )}
        <ConnectWalletButton>
          <>
            <div className="flex flex-col gap-6 w-full">
              <Row>
                <p>
                  Your staked GRMTK:{" "}
                  {formatUnits(BigInt(totalStakedPersonal ?? 0), 18)}
                </p>
                <Button
                  className="w-full max-w-[200px]"
                  disabled={!totalStakedPersonal || totalStakedPersonal === "0"}
                  onClick={unstake}
                >
                  Unstake
                </Button>
              </Row>
              <Row>
                <p>
                  Your pending rewards:{" "}
                  {formatUnits(BigInt(pendingRewards ?? 0), 18)} ETH
                </p>
                <Button
                  className="w-full max-w-[200px]"
                  disabled={!pendingRewards || pendingRewards === "0"}
                  onClick={claim}
                >
                  Claim
                </Button>
              </Row>
            </div>
            <div className="mt-6 self-start w-full">
              <p className="mb-2">
                Available to stake: {formatUnits(BigInt(balance ?? 0), 18)}
              </p>
              <div className="flex">
                <Input
                  placeholder="Enter amount"
                  value={amountToStake}
                  onChange={(val) => updateAmountToStake(val)}
                />
                <Button
                  disabled={!balance || balance === "0"}
                  className="border-0"
                  onClick={() => {
                    updateAmountToStake(formatUnits(BigInt(balance ?? 0), 18));
                  }}
                >
                  Max
                </Button>
              </div>
              <Button
                onClick={stake}
                disabled={!amountToStake}
                className="mt-4"
              >
                Stake
              </Button>
            </div>
          </>
        </ConnectWalletButton>
      </div>
    </>
  );
}
