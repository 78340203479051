import toast from "react-hot-toast";
import { NewTab } from "../components/Svg/NewTab";

export function useTxToast() {
  const transactionSubmitted = (hash: `0x${string}` | undefined) => {
    toast(
      <>
        <div className="text-xs">
          <p>Your transaction has been submitted.</p>
          <a
            className="text-highlight"
            target="_blank"
            href={`https://basescan.org/tx/${hash}`}
          >
            View on Basescan{" "}
            <span className="w-[12px] inline-block">
              <NewTab />
            </span>
          </a>
        </div>
      </>,
      {
        duration: 10000,

        icon: "🚀",
        className: "!bg-dark-2 !text-white",
        position: "top-left",
      }
    );
  };

  const transactionComplete = (hash: `0x${string}` | undefined) => {
    toast(
      <>
        <div className="text-xs">
          <p>Your transaction is complete!</p>
          <a
            className="text-highlight"
            target="_blank"
            href={`https://basescan.org/tx/${hash}`}
          >
            View on Basescan{" "}
            <span className="w-[12px] inline-block">
              <NewTab />
            </span>
          </a>
        </div>
      </>,
      {
        duration: 10000,
        position: "top-left",
        icon: "🎉",
        className: "!bg-dark-2 !text-white",
      }
    );
  };
  return { transactionSubmitted, transactionComplete };
}
