import React, { useState, useEffect } from "react";

type EmojiProps = {
  top: string;
  left: string;
};

const Emoji: React.FC<EmojiProps> = ({ top, left }) => (
  <span
    style={{ top, left }}
    className="absolute text-5xl transform transition-transform duration-1000 animate-bounce delay-1000 animate-fadeOut"
  >
    🎉
  </span>
);

const Celebration: React.FC = () => {
  const [emojis, setEmojis] = useState<Array<EmojiProps>>([]);

  useEffect(() => {
    const generateRandomPosition = (): EmojiProps => ({
      top: `${Math.random() * 100}vh`,
      left: `${Math.random() * 100}vw`,
    });

    const generateEmojis = () => {
      const tempEmojis = [];
      for (let i = 0; i < 100; i++) {
        tempEmojis.push(generateRandomPosition());
      }
      setEmojis(tempEmojis);
    };

    generateEmojis();
  }, []);

  return (
    <div className="fixed pointer-events-none left-0 top-0 w-screen h-screen">
      {emojis.map((emojiProps, idx) => (
        <Emoji key={idx} {...emojiProps} />
      ))}
    </div>
  );
};

export default Celebration;
