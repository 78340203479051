import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import {
  Address,
  WalletClient,
  PublicClient,
  createPublicClient,
  createWalletClient,
  custom,
  http,
  parseUnits,
} from "viem";
import { useAppStore } from "./appstore";
import { GRAMATIK_PROXY } from "../constants";
import gramatik from "../abi/gramatik";

declare global {
  interface Window {
    ethereum: any;
  }
}

interface Web3State {
  address?: Address;
  chainId?: number;
  balance?: string;
  totalStakedGlobal?: string;
  totalStakedPersonal?: string;
  pendingRewards?: string;
  totalCumulativeRewards?: string;
  isConnected: boolean;
  isConnecting: boolean;
  walletClient?: WalletClient;
  publicClient?: PublicClient;
  connect: () => Promise<void>;
  chainChanged: () => Promise<void>;
  onClaimed: () => void;
  hasClaimed?: boolean;
  getBalance: () => Promise<void>;
  getTotalStakedGlobal: () => Promise<void>;
  getTotalStakedPersonal: () => Promise<void>;
  getTotalCumulativeRewards: () => Promise<void>;
  getPendingRewards: () => Promise<void>;
}

// Arb chain ID 42161

export const useWeb3Store = create<Web3State>()(
  devtools(
    persist(
      (set, get) => ({
        isConnected: false as boolean,
        isConnecting: false as boolean,

        onClaimed() {
          set({ hasClaimed: true });
          get().getBalance();
          get().getTotalStakedGlobal();
          get().getTotalStakedPersonal();
          get().getPendingRewards();
        },

        async chainChanged() {
          const client = createPublicClient({
            transport: custom(window.ethereum),
          });
          const chainId = await client.getChainId();
          set({ chainId });
        },
        async getBalance() {
          const balance = await get().publicClient!.readContract({
            address: GRAMATIK_PROXY as Address,
            abi: gramatik,
            functionName: "balanceOf",
            args: [get().address!],
          });
          set({ balance: balance.toString() });
        },
        async getTotalCumulativeRewards() {
          const balance = await get().publicClient!.readContract({
            address: GRAMATIK_PROXY as Address,
            abi: gramatik,
            functionName: "totalCumulativeRewards",
          });
          set({ totalCumulativeRewards: balance.toString() });
        },
        async getTotalStakedGlobal() {
          const balance = await get().publicClient!.readContract({
            address: GRAMATIK_PROXY as Address,
            abi: gramatik,
            functionName: "totalStakedSupply",
          });
          set({ totalStakedGlobal: balance.toString() });
        },
        async getTotalStakedPersonal() {
          const balance = await get().publicClient!.readContract({
            address: GRAMATIK_PROXY as Address,
            abi: gramatik,
            functionName: "totalStaked",
            args: [get().address!],
          });
          set({ totalStakedPersonal: balance.toString() });
        },
        async getPendingRewards() {
          const balance = await get().publicClient!.readContract({
            address: GRAMATIK_PROXY as Address,
            abi: gramatik,
            functionName: "calculateRewards",
            args: [get().address!],
          });
          set({ pendingRewards: balance.toString() });
        },
        async connect() {
          if (typeof window.ethereum !== "undefined") {
            set({ isConnecting: true });
            const walletClient = createWalletClient({
              transport: custom(window.ethereum),
            });
            const publicClient = createPublicClient({
              transport: custom(window.ethereum),
            });
            set({ publicClient, walletClient });
            const [address] = await walletClient.requestAddresses();
            set({ address: address.toLowerCase() as Address });
            const [chainId, hasClaimed] = await Promise.all([
              walletClient.getChainId(),
              publicClient.readContract({
                address: GRAMATIK_PROXY as Address,
                abi: gramatik,
                functionName: "hasClaimed",
                args: [address!],
              }),
              get().getBalance(),
              get().getTotalStakedGlobal(),
              get().getTotalStakedPersonal(),
              get().getTotalCumulativeRewards(),
              get().getPendingRewards(),
            ]);

            set({
              // signer,
              // provider,
              isConnected: true,
              isConnecting: false,
              chainId: chainId,
              hasClaimed,
            });
            useAppStore.getState().getAndSetProof();
          }
        },
      }),
      {
        name: "Web3Store",
        partialize: (state) => ({
          address: state.address,
        }),
      }
    ),
    { name: "Web3Store" }
  )
);

if (typeof window.ethereum !== "undefined") {
  const state = useWeb3Store.getState();
  if (state.address) {
    state.connect();
  }
  window.ethereum.on("chainChanged", () => {
    state.chainChanged();
  });

  window.ethereum.on("accountsChanged", () => {
    state.connect();
  });
}

// @ts-ignore
window.deposit = (amount: string) => {
  useWeb3Store.getState().walletClient?.writeContract({
    address: GRAMATIK_PROXY as Address,
    abi: gramatik,
    functionName: "deposit",
    account: useWeb3Store.getState().address as Address,
    value: parseUnits(amount, 18),
    chain: null,
  });
};
