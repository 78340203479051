import { useContext } from "react";
import { PortalContext, PortalContextType } from "./PortalContextProvider";
import cx from "classnames";

function Tab({
  children,
  label,
}: {
  label: PortalContextType["tab"];
  children?: React.ReactNode;
}) {
  const { updateTab, tab } = useContext(PortalContext);
  return (
    <div
      onClick={() => {
        updateTab(label);
      }}
      className={cx(
        "h-full w-[50%] bg-dark-2 text-white items-center justify-center flex border-b-[5px] border-dark-3 cursor-pointer",
        {
          "border-highlight": tab === label,
        }
      )}
    >
      {children}
    </div>
  );
}

export function Tabs() {
  return (
    <>
      {/* Tabs */}
      <div className="h-[60px] w-full flex">
        <Tab label="stake">Stake</Tab>
        <Tab label="claim">Claim</Tab>
      </div>
    </>
  );
}
