import React, { createContext, useCallback, useState } from "react";

export type PortalContextType = {
  tab: "stake" | "claim";
  updateTab: (tab: PortalContextType["tab"]) => void;
};

export const PortalContext = createContext<PortalContextType>({
  tab: "stake",
  updateTab: () => void 0,
});

export function PortalContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [tab, _updateTab] = useState<PortalContextType["tab"]>("stake");
  const updateTab = useCallback((tab: PortalContextType["tab"]) => {
    _updateTab(tab);
  }, []);
  return (
    <PortalContext.Provider
      value={{
        tab,
        updateTab,
      }}
    >
      {children}
    </PortalContext.Provider>
  );
}
