import React, { useContext } from "react";
import { Wrap } from "../Wrap";
import { useWeb3Store } from "../../stores/web3store";
import { Tabs } from "./Tabs";
import { ConnectWalletButton } from "../ConnectWalletButton";
import { Stake } from "./Stake";
import { Claim } from "./Claim";
import { PortalContext } from "./PortalContextProvider";
import { base } from "viem/chains";
import { useConnect, useSwitchChain } from "wagmi";

export function Portal() {
  const web3Store = useWeb3Store();
  const { tab } = useContext(PortalContext);
  const { switchChain } = useSwitchChain();

  return (
    <>
      <Wrap className="mt-20">
        {web3Store.chainId !== base.id && (
          <p
            onClick={() => {
              switchChain({ chainId: base.id });
            }}
            className="text-red text-center pb-2 cursor-pointer underline"
          >
            Please switch to the Base network
          </p>
        )}
        <div className="w-100 flex justify-center">
          <div className="border border-dark-3 bg-dark-1 max-w-[834px] w-full rounded-xl overflow-hidden">
            <Tabs />
            <div className="px-[20px] py-8">
              {tab === "stake" && <Stake />}
              {tab === "claim" && <Claim />}
            </div>
          </div>
        </div>
      </Wrap>
    </>
  );
}
