/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useWeb3Store } from "../../stores/web3store";
import { GRAMATIK_PROXY, PROOF_PATH } from "../../constants";
import { ConnectWalletButton } from "../ConnectWalletButton";
import { useAppStore } from "../../stores/appstore";
import { Address, formatUnits, parseUnits } from "viem";
import { Button } from "../Button";
import gramatik from "../../abi/gramatik";
import { PortalContext } from "./PortalContextProvider";
import Celebration from "../Celebration";
import { mainnet } from "viem/chains";
import { useTxToast } from "../../hooks/useTxToast";

export function Claim() {
  const [done, updateDone] = useState(false);
  const { updateTab } = useContext(PortalContext);
  const { proof, snapshot } = useAppStore((state) => ({
    proof: state.proof,
    snapshot: state.snapshot,
  }));
  const [txLoading, updateTxLoading] = useState(false);
  const {
    hasClaimed,
    onClaimed,
    publicClient,
    walletClient,
    address,
    connected,
  } = useWeb3Store((state) => ({
    hasClaimed: state.hasClaimed,
    walletClient: state.walletClient,
    connected: state.isConnected,
    publicClient: state.publicClient,
    address: state.address,
    onClaimed: state.onClaimed,
  }));
  const { transactionSubmitted, transactionComplete } = useTxToast();

  let canStake = false;
  const [claimedAndStaked, updateOnClaimAndStake] = useState(false);
  if (
    snapshot?.amount &&
    BigInt(snapshot.amount) >= parseUnits("1000", 18) &&
    BigInt(snapshot.amount) <= parseUnits("100000", 18)
  ) {
    canStake = true;
  }
  const { claim, claimAndStake } = useMemo(() => {
    const claim = async () => {
      try {
        updateTxLoading(true);
        const hash = await walletClient?.writeContract({
          address: GRAMATIK_PROXY as Address,
          abi: gramatik,
          functionName: "claim",
          account: address as Address,
          args: [snapshot.amount, proof],
          chain: null,
        });
        transactionSubmitted(hash);
        await publicClient?.waitForTransactionReceipt({
          hash: hash!,
        });
        transactionComplete(hash);
        onClaimed();
        updateDone(true);
      } catch (e) {
        updateTxLoading(false);
      }
    };
    const claimAndStake = async () => {
      try {
        updateTxLoading(true);
        console.log([snapshot.amount, proof]);
        const hash = await walletClient?.writeContract({
          address: GRAMATIK_PROXY as Address,
          abi: gramatik,
          functionName: "claimAndStake",
          account: address as Address,
          args: [snapshot.amount, proof],
          chain: null,
        });
        transactionSubmitted(hash);

        await publicClient?.waitForTransactionReceipt({
          hash: hash!,
        });
        transactionComplete(hash);
        onClaimed();
        updateOnClaimAndStake(true);
        updateDone(true);
      } catch (e) {
        console.log(e);
        updateTxLoading(false);
      }
    };

    return { claim, claimAndStake };
  }, [snapshot?.amount, address, onClaimed, proof, publicClient, walletClient]);

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        {!connected && (
          <p className="mb-4">
            Connect your wallet to check if you are eligible to claim GRMTK
            token.
          </p>
        )}
        <ConnectWalletButton>
          {done && (
            <>
              <Celebration />
              <p className="text-center mb-4">
                🎉 You have claimed your GRMTK token!
              </p>
              {claimedAndStaked && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    updateTab("stake");
                  }}
                  className="text-highlight"
                  href="#"
                >
                  Go to staking.
                </a>
              )}
              {!claimedAndStaked && (
                <p className="text-center mt-4">
                  Now you can{" "}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      updateTab("stake");
                    }}
                    className="text-highlight"
                    href="#"
                  >
                    stake to earn a percentage of royalties.
                  </a>
                </p>
              )}
            </>
          )}

          {!done && (
            <>
              {proof && !hasClaimed && (
                <>
                  <p className="mb-2 text-center">
                    🎉 You are eligible to claim{" "}
                    {formatUnits(snapshot?.amount, 18).toLocaleString()} GRMTK
                  </p>
                  {!canStake && (
                    <Button
                      className="mt-4 w-full max-w-[300px]"
                      disabled={txLoading}
                      onClick={claim}
                    >
                      <>{txLoading ? "Please wait..." : "Claim now"}</>
                    </Button>
                  )}
                  {canStake && (
                    <>
                      <Button
                        className="mt-4 w-full max-w-[300px]"
                        disabled={txLoading}
                        onClick={claimAndStake}
                      >
                        <>{txLoading ? "Please wait..." : "Claim + Stake"}</>
                      </Button>
                      <Button
                        className="mt-4 w-full max-w-[300px]"
                        disabled={txLoading}
                        onClick={claim}
                      >
                        <>{txLoading ? "Please wait..." : "Claim only"}</>
                      </Button>
                    </>
                  )}
                </>
              )}
              {!proof && <>😢 You are not eligible to claim.</>}
              {hasClaimed && (
                <>
                  <p className="mb-4">You have already claimed. </p>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      updateTab("stake");
                    }}
                    className="text-highlight"
                    href="#"
                  >
                    Stake now
                  </a>
                </>
              )}
            </>
          )}
        </ConnectWalletButton>
      </div>
    </>
  );
}
