import React from "react";
import { Navigation } from "./components";
import { Portal } from "./components/Portal";
import { PortalContextProvider } from "./components/Portal/PortalContextProvider";
import { Footer } from "./components/Footer";
import { Toaster } from "react-hot-toast";
import { http, createConfig, WagmiProvider, useConnect } from "wagmi";
import { base } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const config = createConfig({
  chains: [base],
  transports: {
    [base.id]: http(),
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <Toaster />
          <Navigation />
          <PortalContextProvider>
            <Portal />
          </PortalContextProvider>
          <Footer />
        </div>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
