import React from "react";
import cx from "classnames";

export function Button({
  tag = "button",
  children,
  onClick,
  disabled,
  className,
}: {
  onClick?: (() => void) | (() => Promise<void>);
  tag?: "button" | "a";
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
}) {
  const props = {
    className: cx(
      "border-white border text-highlight py-[10px] px-[40px]",
      {
        "opacity-25": disabled,
      },
      className
    ),
    onClick: disabled ? void 0 : onClick,
  };
  return <button {...props}>{children}</button>;
}
